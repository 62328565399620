/* @font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url('vendor/fonts/Inter-Regular.woff2') format('woff2'),
       url('vendor/fonts/Inter-Regular.woff') format('woff');
} */

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url('vendor/fonts/Inter-Medium.woff2') format('woff2'),
       url('vendor/fonts/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url('vendor/fonts/Inter-SemiBold.woff2') format('woff2'),
       url('vendor/fonts/Inter-SemiBold.woff') format('woff');
}

/* @font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url('vendor/fonts/Inter-Bold.woff2') format('woff2'),
       url('vendor/fonts/Inter-Bold.woff') format('woff');
} */

body {
  margin: 0;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  touch-action: pan-x pan-y;
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

html, body, #root {
  height: 100%;
  overflow: hidden;
}

iframe {
  pointer-events: none;
}

wcm-modal {
  display: none;
}